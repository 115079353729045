// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}
.card-subtitle {
  margin-top: -6px;
  margin-bottom: 0;
}
